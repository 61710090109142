import './App.css';
import { BrowserRouter as Router, Route, Routes, } from 'react-router-dom';
import { Header, Footer } from './widgets/Widgets';
import {Paginas, Catalogo, Registro, CatalogoCanje, CatalogoCanjeError} from './secciones/Paginas';
import {Canjeo, Envio} from './secciones/Canjeo';
import {Login, Logout} from './secciones/Login';
import {Admin, LoginAdmin, ExportAdmin} from './secciones/Admin';
import Home from './secciones/Home';
import Webinar2023 from './secciones/Webinar';
import NotFound from './secciones/NotFound';

import { AuthProvider } from './context/AuthContext';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/catalogo" element={<Catalogo />} />
          <Route path="/registro" element={<Registro />} />
          <Route path="/webinars-2023" element={<Webinar2023 />} />

          {/* <Route path="/login" element={<Login />} />
          <Route path="/canjeo/" element={<Login />} />
          <Route path="/canjeo/:id/:token" element={<Canjeo />} />
          <Route path="/canjear/:id/:token" element={<Envio />} /> */}

          <Route path="/login/admin" element={<LoginAdmin />} />
          <Route path="/admin/importar" element={<ExportAdmin />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
    </AuthProvider>
  );
}

export default App;

